import * as React from "react";
import Layout from "../components/Layout/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import SEO from "../components/seo";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomep {
        nodes {
          data {
            active_rider_text {
              text
            }
            distance_text {
              text
            }
            trees_text {
              text
            }
            emmission_text {
              text
            }
            report_card_link {
              url
            }
            active_riders
            active_riders_lg {
              gatsbyImageData
            }
            distance_lg {
              gatsbyImageData
            }
            emmission_lg {
              gatsbyImageData
            }
            trees_lg {
              gatsbyImageData
            }
            corporate_overline
            distance_travelled
            emissions_saved
            trees_planted
            cards {
              description
              overline
              title
              background_lg {
                gatsbyImageData
              }
              background_md {
                gatsbyImageData
              }
              background_sm {
                gatsbyImageData
              }
              background_xl {
                gatsbyImageData
              }
              link_01 {
                url
              }
              link_02 {
                url
              }
            }
            corporate_carousel {
              card_link {
                url
              }
              image_lg {
                gatsbyImageData
              }
              image_md {
                gatsbyImageData
              }
              image_sm {
                gatsbyImageData
              }
              image_xl {
                gatsbyImageData
              }
              overline
            }
            corporate_title {
              text
            }
            environment_cards {
              button_link {
                url
              }
              description {
                text
              }
              image_lg {
                gatsbyImageData
              }
              image_md {
                gatsbyImageData
              }
              image_sm {
                gatsbyImageData
              }
              image_xl {
                gatsbyImageData
              }
              overline
              subtitle {
                text
              }
              title {
                text
              }
            }
            general_questions {
              answer {
                text
              }
              question {
                text
              }
            }
            product {
              answer {
                text
              }
              question {
                text
              }
            }
          }
        }
      }
    }
  `);

  console.log("homeP data", data);

  return (
    <>
    <SEO 
    title = "Kabira Mobility"/>
      <Layout>
        <main className="lg:mx-auto grid grid-cols-1 md:grid-cols-2 gap-3 py-2 md:px-0 bg-center bg-gray-100">
          {/* KM3000 */}
          <section
            className="h-[720px] bg-top bg-no-repeat bg-cover col-span-2 flex flex-row items-start justify-center bg-white -mt-2"
            style={{
              backgroundImage: `url("https://images.prismic.io/kabiramobilitycms/7b8ebebc-ec14-4fa1-8c2d-aba57e6e20b5_%5BResizer+1.png?auto=compress,format")`,
            }}
          >
            <div className="flex flex-col items-center mx-0 md:mx-48 mt-12 space-y-4">
              <h1 className="text-xl lg:text-2xl font-light text-gray-700 text-center leading-loose">
                Let's become Responsible for <br />{" "}
                <span className="text-5xl md:text-7xl font-bold leading-snug">
                  Climate Control
                </span>
              </h1>
              <p className="text-xl uppercase font-bold text-white mt-4">
                coming soon{" "}
              </p>
            </div>
          </section>

          {/* 
          ================
          2XL - KM3000
          ================
          */}
          <section
            className="h-[560px] md:h-[720px] bg-bottom bg-no-repeat col-span-2 2xl:flex flex-row items-center justify-end bg-white hidden"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632913612/KM3000-homePage-2XL_xk4rm8.png")`,
            }}
          >
            <div className="flex flex-col items-end mx-0 md:mx-48 space-y-4">
              <h2 className="text-7xl font-semibold text-red-700">KM3000</h2>
              <p className="text-2xl font-light text-gray-500">
                Performance Redefined
              </p>
              <p className="text-xl uppercase font-bold text-gray-500 mt-4">
                coming soon{" "}
              </p>
            </div>
          </section>

          {/* 
          ================
          XL - KM3000
          ================
          */}
          <section
            className="h-[560px] md:h-[720px] bg-bottom bg-no-repeat col-span-2 xl:flex flex-row items-center justify-end bg-white hidden 2xl:hidden"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632913612/KM3000-homePage-XL_jjxwc4.png")`,
            }}
          >
            <div className="flex flex-col items-end mx-0 md:mx-48 space-y-4">
              <h2 className="text-7xl font-semibold text-red-700">KM3000</h2>
              <p className="text-2xl font-light text-gray-500">
                Performance Redefined
              </p>
              <p className="text-xl uppercase font-bold text-gray-500 mt-4">
                coming soon{" "}
              </p>
            </div>
          </section>
          {/* 
          ================
          LG - KM3000
          ================
          */}
          <section
            className="h-[560px] md:h-[720px] bg-bottom bg-no-repeat col-span-2 lg:flex flex-row items-center justify-end bg-white hidden xl:hidden"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632913611/KM3000-homePage-LG_bca4j9.png")`,
            }}
          >
            <div className="flex flex-col items-end mx-0 md:mx-24 space-y-4">
              <h2 className="text-7xl font-semibold text-red-700">KM3000</h2>
              <p className="text-2xl font-light text-gray-500">
                Performance Redefined
              </p>
              <p className="text-xl uppercase font-bold text-gray-500 mt-4">
                coming soon{" "}
              </p>
            </div>
          </section>

          {/* 
          ================
          MD - KM3000
          ================
          */}
          <section
            className="h-[872px] bg-bottom bg-no-repeat col-span-2 md:flex flex-row items-start justify-center pt-12 bg-white hidden lg:hidden"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632913612/KM3000-homePage-MD_ohcmb3.png")`,
            }}
          >
            <div className="flex flex-col items-center mx-0 md:mx-48 space-y-4">
              <h2 className="text-7xl font-bold text-red-700">KM3000</h2>
              <p className="text-2xl font-light text-gray-500">
                Performance Redefined
              </p>
              <p className="text-xl uppercase font-bold text-gray-500 mt-4">
                coming soon{" "}
              </p>
            </div>
          </section>

          {/* 
          ================
          SM - KM3000
          ================
          */}
          <section
            className="h-[560px] bg-bottom bg-no-repeat col-span-2 flex flex-row items-start justify-center pt-12 bg-white md:hidden"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632913612/KM3000-homePage-SM_sjomkv.png")`,
            }}
          >
            <div className="flex flex-col items-center mx-0 md:mx-48 space-y-4">
              <h2 className="text-5xl font-bold md:font-semibold text-red-700">
                KM3000
              </h2>
              <p className="text-xl font-light text-gray-500">
                Performance Redefined
              </p>
              <p className="text-xl uppercase font-bold text-gray-500 mt-4">
                coming soon{" "}
              </p>
            </div>
          </section>
          {/* 
          ================
          2XL - KM4000
          ================
           */}
          <section
            className="h-[720px] bg-bottom bg-no-repeat col-span-2 2xl:flex flex-row items-center justify-start bg-white hidden"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632916420/KM4000-homePage-2XL_w9nyij.png")`,
            }}
          >
            <div className="flex flex-col items-start mx-48 space-y-4">
              <h2 className="text-7xl font-semibold text-green-700">KM4000</h2>
              <p className="text-2xl font-light text-gray-500">
                The Silent Warrior
              </p>
              <p className="text-xl uppercase font-bold text-gray-500 mt-4">
                coming soon{" "}
              </p>
            </div>
          </section>

          {/* 
          ================
          XL - KM4000
          ================
          */}
          <section
            className="h-[560px] md:h-[720px] bg-bottom bg-no-repeat col-span-2 xl:flex flex-row items-center justify-start bg-white hidden 2xl:hidden"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632916414/KM4000-homePage-XL_ge8lyc.png")`,
            }}
          >
            <div className="flex flex-col items-start mx-0 md:mx-48 space-y-4">
              <h2 className="text-7xl font-semibold text-green-700">KM4000</h2>
              <p className="text-2xl font-light text-gray-500">
                The Silent Warrior
              </p>
              <p className="text-xl uppercase font-bold text-gray-500 mt-4">
                coming soon{" "}
              </p>
            </div>
          </section>

          {/* 
          ================
          LG - KM4000
          ================
          */}
          <section
            className="h-[560px] md:h-[720px] bg-bottom bg-no-repeat col-span-2 lg:flex flex-row items-center justify-start bg-white hidden xl:hidden"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632916413/KM4000-homePage-LG_byui3r.png")`,
            }}
          >
            <div className="flex flex-col items-start mx-0 md:mx-24 space-y-4">
              <h2 className="text-7xl font-semibold text-green-700">KM4000</h2>
              <p className="text-2xl font-light text-gray-500">
                The Silent Warrior
              </p>
              <p className="text-xl uppercase font-bold text-gray-500 mt-4">
                coming soon{" "}
              </p>
            </div>
          </section>

          {/* 
          ================
          MD - KM4000
          ================
          */}
          <section
            className="h-[872px] bg-bottom bg-no-repeat col-span-2 md:flex flex-row items-start justify-center pt-12 bg-white hidden lg:hidden"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632916413/KM4000-homePage-MD_ja5mse.png")`,
            }}
          >
            <div className="flex flex-col items-center mx-0 md:mx-48 space-y-4">
              <h2 className="text-7xl font-bold text-green-700">KM4000</h2>
              <p className="text-2xl font-light text-gray-500">
                Performance Redefined
              </p>
              <p className="text-xl uppercase font-bold text-gray-500 mt-4">
                coming soon{" "}
              </p>
            </div>
          </section>

          {/* 
          ================
          SM - KM4000
          ================
           */}
          <section
            className="h-[560px] bg-bottom bg-no-repeat col-span-2 flex flex-row items-start justify-center pt-12 bg-white md:hidden"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632916413/KM4000-homePage-SM_qdxdol.png")`,
            }}
          >
            <div className="flex flex-col items-center mx-0 md:mx-48 space-y-4">
              <h2 className="text-5xl font-bold md:font-semibold text-green-700">
                KM4000
              </h2>
              <p className="text-xl font-light text-gray-500">
                The Silent Warrior
              </p>
              <p className="text-xl uppercase font-bold text-gray-500 mt-4">
                coming soon{" "}
              </p>
            </div>
          </section>

          <div className="mx-2 col-span-2 grid grid-cols-2 gap-3">
            <div
              className="w-full lg:h-[640px] h-[520px] bg-gray-100 bg-cover bg-bottom col-span-2 md:col-span-1 rounded-lg shadow-lg p-4"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632929324/Frame_558_m9hdyq.png")`,
              }}
            >
              <div className="flex flex-col items-center mt-10 space-y-4">
                <h1 className="text-4xl lg:text-4xl text-center font-semibold text-gray-900">
                  Electric Scooters
                </h1>
                <p className="text-lg font-light text-gray-500">
                  Eco-Friendly Mobility
                </p>
                <Link to = "/products">
                  <span className="group ">
                    <button className="py-3 px-6 rounded-full border-2 border-blue-700  group-hover:bg-blue-700 group-hover:shadow flex flex-row space-x-2 items-center">
                      <span className="text-base font-semibold group-hover:text-white text-blue-700">
                        Learn More{" "}
                      </span>
                      <span>
                        <ChevronRightIcon className="h-5 w-5 text-blue-700 group-hover:text-white" />
                      </span>
                    </button>
                  </span>
                </Link>
              </div>
            </div>
            <div
              className="w-full lg:h-[640px] h-[520px] bg-gray-100 bg-cover bg-bottom col-span-2 md:col-span-1 rounded-lg shadow-lg p-4"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/kabira-mobility/image/upload/v1632927779/Frame_557_qyywua.png")`,
              }}
            >
              <div className="flex flex-col items-center mt-10 space-y-4">
                <h1 className="text-4xl lg:text-4xl text-center font-semibold text-gray-900">
                  Delivery Scooters
                </h1>
                <p className="text-lg font-light text-gray-500 leading-normal text-center">
                  The Electric Bull
                </p>
                <p className="text-base uppercase font-bold text-gray-500 mt-4">
                  coming soon{" "}
                </p>
              </div>
            </div>
            <div
              className="w-full lg:h-[640px] h-[520px] bg-gray-100 bg-cover bg-bottom col-span-2 md:col-span-1 rounded-lg shadow-lg p-4"
              style={{
                backgroundImage: `url("https://images.prismic.io/kabiramobilitycms/74909a95-3c1e-40c7-95c1-2fbe90bc5b8e_Maps+SVG+2.png?auto=compress,format")`,
              }}
            >
              <div className="flex flex-col items-center mt-10 space-y-4">
                <h1 className="text-4xl lg:text-4xl text-center font-semibold text-gray-900">
                  Locate a Store{" "}
                </h1>
                <p className="text-lg font-light text-gray-700">
                  Find your Nearest Dealer
                </p>
                <Link to = "/dealers">
                  <span className="group ">
                    <button className="py-3 px-6 rounded-full border-2 border-blue-700  group-hover:bg-blue-700 group-hover:shadow flex flex-row space-x-2 items-center">
                      <span className="text-base font-semibold group-hover:text-white text-blue-700">
                        Learn More{" "}
                      </span>
                      <span>
                        <ChevronRightIcon className="h-5 w-5 text-blue-700 group-hover:text-white" />
                      </span>
                    </button>
                  </span>
                </Link>
              </div>
            </div>
            <div
              className="w-full lg:h-[640px] h-[520px] bg-gray-100 bg-cover bg-bottom col-span-2 md:col-span-1 rounded-lg shadow-lg p-4"
              style={{
                backgroundImage: `url("https://images.prismic.io/kabiramobilitycms/e2cec215-1e56-4a6e-b745-94b39211a3d9_TestRide-2+Raw.png?auto=compress,format")`,
              }}
            >
              <div className="flex flex-col items-center mt-10 space-y-4">
                <h1 className="text-4xl lg:text-4xl text-center font-semibold text-white">
                  Test Rides
                </h1>
                <p className="text-lg font-light text-white">
                  Experience the Future of Mobility
                </p>
                <Link to = "/contact-us">
                  <span className="group ">
                    <button className="py-3 px-6 rounded-full border-2 border-white group-hover:border-blue-700 group-hover:bg-blue-700 group-hover:shadow flex flex-row space-x-2 items-center">
                      <span className="text-base font-semibold text-white">
                        Register Now{" "}
                      </span>
                      <span>
                        <ChevronRightIcon className="h-5 w-5 text-white group-hover:text-white" />
                      </span>
                    </button>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
